import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import XMLHttpRequest from "xhr2";
import Layout from "../components/Layout";
import Img from "gatsby-image";
import CustomSoundPlayer from "../components/CustomSoundPlayer";
import NewsletterSingup from "../components/newsletterSignup";
import ReactPlayer from "react-player";
import { isYoutubeLink } from "../utils";

import {
  FaItunesNote,
  FaSoundcloud,
  FaSpotify,
  FaGooglePlay,
  FaPrint,
  FaYoutube
} from "react-icons/fa";
import { ReactComponent as Stitcher } from "../img/stitcher.svg";
import SubscribeModal from "../components/SubscribeModal.jsx";

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      firstName: "",
      lastName: "",
      city: "",
      state: "",
      zip: "",

      showURLtoURI: {},
      fetchingURLs: {},

      filter: "",
      selectedSeries: "",
      selectedCollection: "",
      amountToLoad: 4
    };

    this.renderShows = this.renderShows.bind(this);
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  handleSubmita = event => {
    let { email, firstName, lastName, city, state, zip } = this.state;
    let toPost = {
      email_address: email,
      status: "subscribed",
      merge_fields: {
        FNAME: firstName,
        LNAME: lastName
      }
    };
  };

  fetchSoundCloudUrl(url) {
    let clientId = "qy5YraPpqlufxQGsAhiNCCbDgtnUAgNx";
    let request = new XMLHttpRequest();
    request.open(
      "GET",
      `https://api.soundcloud.com/resolve?url=${url}&client_id=${clientId}`
    );
    request.onload = () => {
      var data = JSON.parse(request.responseText);
      this.setState({
        showURLtoURI: { ...this.state.showURLtoURI, [url]: data.uri },
        fetchingURLs: { ...this.state.fetchingURLs, [url]: false }
      });
    };
    this.setState(
      {
        fetchingURLs: { ...this.state.fetchingURLs, [url]: true }
      },
      () => request.send()
    );
  }

  getSoundCloudUrl(url) {
    if (
      this.state.showURLtoURI[url] === undefined &&
      this.state.fetchingURLs[url] === undefined
    ) {
      this.fetchSoundCloudUrl(url);
      return false;
    } else {
      return this.state.showURLtoURI[url];
    }
  }

  postContainsTag(post, inputTag) {
    return (post.frontmatter.tags || []).some(tag => {
      if (inputTag === "Doctor / Health / Nutrition") {
        return (
          tag.toLowerCase().includes("doctor") ||
          tag.toLowerCase().includes("health") ||
          tag.toLowerCase().includes("nutrition")
        );
      }
      return tag.toLowerCase().includes(inputTag.toLowerCase());
    });
  }

  renderShows() {
    let renderedShows = 0;
    const { data } = this.props;
    let { selectedCollection, selectedSeries } = this.state;
    let showMap = {};
    let shows = data.shows.edges.map(show => {
      showMap[show.node.frontmatter.title] = show.node;
      return show.node;
    });
    let showsToRender;

    if (selectedCollection !== "") {
      let collection = data.collections.edges.find(
        collection => collection.node.frontmatter.title === selectedCollection
      ).node;
      showsToRender = collection.frontmatter.shows.map(showTitle => {
        return showMap[showTitle];
      });
    } else {
      showsToRender = shows.map(show => {
        if (
          selectedSeries !== "" &&
          !this.postContainsTag(show, selectedSeries)
        ) {
          return null;
        }
        if (
          this.state.filter === "" ||
          show.frontmatter.title
            .toLowerCase()
            .includes(this.state.filter.toLowerCase()) ||
          show.excerpt
            .toLowerCase()
            .includes(this.state.filter.toLowerCase()) ||
          (show.tags || []).some(tag =>
            tag.toLowerCase().includes(this.state.filter.toLowerCase())
          )
        ) {
          return show;
        }
        return null;
      });
    }

    showsToRender = showsToRender.filter(show => show !== null);

    return (
      <React.Fragment>
        {showsToRender.map((post, i) => {
          if (renderedShows >= this.state.amountToLoad) {
            return null;
          }
          renderedShows++;
          return (
            <div
              className="content"
              style={{ padding: "0em 2em 2em" }}
              key={post.id}
            >
              <div className="front-page-post-title">
                <Link className="has-text-primary" to={post.fields.slug}>
                  {post.frontmatter.title}
                </Link>
              </div>
              <div className="front-page-post-date">
                {post.frontmatter.date}
              </div>
              <p className="front-page-post-content">{post.excerpt}</p>
              {isYoutubeLink(post.frontmatter.youtubeLink) ? (
                <a
                  href={post.frontmatter.youtubeLink}
                  rel="noopener"
                  target="#"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "0.4rem"
                  }}
                >
                  <FaYoutube size={"30px"} style={{ marginRight: "1rem" }} />
                  <span>Watch on Youtube</span>
                </a>
              ) : (
                this.getSoundCloudUrl(post.frontmatter.soundcloud) !==
                  false && (
                  <iframe
                    style={{ marginTop: "0.6rem" }}
                    width="100%"
                    height="20"
                    scrolling="no"
                    frameborder="no"
                    allow="autoplay"
                    src={`https://w.soundcloud.com/player/?url=${post.frontmatter.soundcloud}&color=%23ff5500&inverse=false&auto_play=false&show_user=true`}
                  ></iframe>
                )
              )}
              {/*<CustomSoundPlayer
                resolveUrl={post.frontmatter.soundcloud}
                clientId={clientId}
                onReady={() => {
                  console.log("player url ready!");
                }}
              />*/}
            </div>
          );
        })}
        {renderedShows === 0 && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "2rem"
            }}
          >
            No shows found
          </div>
        )}
        {showsToRender.length > this.state.amountToLoad && (
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-primary"
              onClick={() =>
                this.setState({
                  amountToLoad: this.state.amountToLoad + 5
                })
              }
            >
              View More +
            </button>
          </div>
        )}
      </React.Fragment>
    );
  }

  render() {
    const { data } = this.props;

    const { edges: posts } = (data || {}).shows;
    const { edges: collections } = (data || {}).collections || {};

    let clientId = "qy5YraPpqlufxQGsAhiNCCbDgtnUAgNx";

    let tags = new Set([]);

    posts.forEach(post => {
      ((post.node.frontmatter || {}).tags || []).forEach(tag => {
        tags.add(tag);
      });
    });

    let selectableSeries = [
      "Sean Commentary",
      "CV19",
      "Politics",
      "Business/Economy",
      "Guest Interview",
      "Congress",
      "Exposing Corruption",
      "U.S. Constitution",
      "Doctor/Health/Nutrition",
      "National Debt",
      "Changemakers",
      "Science/Technology",
      "International Discussion",
      "Improving the Environment",
      "Doctor Series",
      "Federal Reserve",
      "Benefitting Children",
      "Education",
      "Solving Homelessness",
      "Drugging of our Vets",
      "Drugs/Addiction",
      "Big Pharma",
      "Electoral College",
      "Mass Shootings",
      "Supreme Court",
      "U.S. Constitution ",
      "Benefiting Children",
      "Mass Media/Social Media",
      "How to Make Change Series"
    ];

    return (
      <Layout>
        <SubscribeModal />
        <section className="section">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="content">
                  <Img fluid={data.image.childImageSharp.fluid} />
                  <p className="pt-4">
                    <h1 style={{ marginBottom: "1rem", fontSize: "1.2rem" }}>
                      The Sean Burke Show
                    </h1>
                    On WSMN 1590 AM and 95.3 FM <br />
                    out of Nashua, NH
                  </p>

                  <div className="pt-2">
                    <div className="pb-1">Available to listen on:</div>
                    <a
                      href="https://itunes.apple.com/us/podcast/sean-burke-show/id1323542319?mt=2"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="podcast-site-icon"
                    >
                      <FaItunesNote size={"30px"} />
                    </a>
                    <a
                      href="https://soundcloud.com/user-107157598/sean-burke-show-april-7-2018"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="podcast-site-icon"
                    >
                      <FaSoundcloud size={"30px"} />
                    </a>
                    <a
                      href="https://open.spotify.com/show/25gqltvA2hIyQdCuJ2rogl?si=j2DTQstaScK-0yUB79K4yw"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="podcast-site-icon"
                    >
                      <FaSpotify size={"30px"} />
                    </a>
                    <a
                      href="https://www.stitcher.com/podcast/sean-burke-show?refid=stpr"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="podcast-site-icon"
                    >
                      <Stitcher />
                    </a>
                    <a
                      href="https://playmusic.app.goo.gl/?ibi=com.google.PlayMusic&isi=691797987&ius=googleplaymusic&apn=com.google.android.music&link=https://play.google.com/music/m/I3bcahazlyut2rshye5ynnfyzny?t%3DSean_Burke_Show%26pcampaignid%3DMKT-na-all-co-pr-mu-pod-16"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="podcast-site-icon"
                    >
                      <FaGooglePlay size={"30px"} />
                    </a>
                  </div>
                  <div>
                    Email Sean at{" "}
                    <a href="mailto:heysean@seanburke.net">
                      heysean@seanburke.net
                    </a>
                  </div>
                  <div className="pt-4">
                    Register below to receive a link to the show after it airs,
                    as well as updates from Sean:
                  </div>
                  <NewsletterSingup />
                </div>
              </div>
              <div className="col-md-8">
                <div className="series-filters-header">
                  <div
                    className="d-flex justify-content-between align-items-center"
                    style={{ marginBottom: "1.5rem" }}
                  >
                    <h5>Tagged shows:</h5>
                    {this.state.selectedSeries !== "" && (
                      <button
                        onClick={() => this.setState({ selectedSeries: "" })}
                        style={{}}
                        className="button secondary"
                      >
                        Clear selected tags
                      </button>
                    )}
                  </div>
                  {selectableSeries.map(series => {
                    return (
                      <span
                        className={`button tag ${
                          this.state.selectedSeries === series ? "active" : ""
                        }`}
                        onClick={() => {
                          if (this.state.selectedSeries === series) {
                            this.setState({ selectedSeries: "" });
                          } else {
                            this.setState({
                              selectedSeries: series,
                              selectedCollection: "",
                              amountToLoad: 4
                            });
                          }
                        }}
                      >
                        {series}
                      </span>
                    );
                  })}
                </div>
                <div
                  className="series-filters-header"
                  style={{ marginBottom: "0.8rem" }}
                >
                  <div
                    className="d-flex justify-content-between align-items-center"
                    style={{ marginBottom: "1rem" }}
                  >
                    <h5>Collections:</h5>
                    {this.state.selectedCollection !== "" && (
                      <button
                        onClick={() =>
                          this.setState({ selectedCollection: "" })
                        }
                        style={{}}
                        className="button secondary"
                      >
                        Clear collection
                      </button>
                    )}
                  </div>
                  {collections
                    .sort((a, b) => {
                      return a.node.frontmatter.title > b.node.frontmatter.title
                        ? -1
                        : 1;
                    })
                    .map(collection => {
                      return (
                        <span
                          className={`button tag ${
                            this.state.selectedCollection ===
                            collection.node.frontmatter.title
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            if (
                              this.state.selectedCollection ===
                              collection.node.frontmatter.title
                            ) {
                              this.setState({ selectedCollection: "" });
                            } else {
                              this.setState({
                                selectedCollection:
                                  collection.node.frontmatter.title,
                                selectedSeries: "",
                                amountToLoad: 4
                              });
                            }
                          }}
                        >
                          {collection.node.frontmatter.title}
                        </span>
                      );
                    })}
                </div>
                <div className="latest-episodes-header">
                  <h5>
                    {this.state.selectedCollection !== ""
                      ? this.state.selectedCollection + ":"
                      : "Latest episodes:"}
                  </h5>
                  <div>
                    {this.state.selectedCollection === "" && (
                      <input
                        className="form-control"
                        type="text"
                        name="filter"
                        value={this.state.filter}
                        onChange={this.handleInputChange}
                        placeholder={"Search"}
                      />
                    )}
                  </div>
                </div>
                {this.renderShows()}
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    posts: PropTypes.shape({
      edges: PropTypes.array
    })
  })
};

export const pageQuery = graphql`
  query {
    shows: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 4000000)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
            iTunes
            soundcloud
            youtubeLink
            tags
          }
        }
      }
    }
    collections: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "collection" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            shows
          }
        }
      }
    }
    image: file(relativePath: { eq: "sean.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
