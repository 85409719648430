import React from "react";

import { Loader } from "react-feather";
import addToMailchimp from "gatsby-plugin-mailchimp";

export default class IndexPage extends React.Component {
  state = {
    email: "",
    firstName: "",
    lastName: "",
    city: "",
    state: "",
    zip: "",

    subscribing: false,
    subscribed: false,
    failedSubscribe: false
  };

  handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  _handleSubmit = e => {
    e.preventDefault();

    if (this.state.subscribing) {
      return;
    }

    let listFields = {
      FNAME: this.state.firstName,
      LNAME: this.state.lastName,
      CITY: this.state.city,
      STATE: this.state.state,
      ZIP: this.state.zip
    };

    this.setState({
      subscribing: true,
      subscribed: false,
      failedSubscribe: false
    });

    addToMailchimp(this.state.email, listFields)
      .then(data => {
        if (data.result === "success") {
          this.setState({
            subscribing: false,
            subscribed: true,
            failedSubscribe: false,
            email: "",
            firstName: "",
            lastName: "",
            city: "",
            state: "",
            zip: ""
          });
        } else {
          this.setState({
            subscribing: false,
            subscribed: false,
            failedSubscribe: true
          });
        }
      })
      .catch(() => {
        // unnecessary because Mailchimp only ever
        // returns a 200 status code
      });
  };

  render() {
    return (
      <form
        name="contact"
        onSubmit={this._handleSubmit}
        className="signup-form"
      >
        <input type="hidden" name="form-name" value="contact" />
        <div className="form-group">
          <label for="inputEmail">Email Address</label>
          <input
            className="form-control"
            name="email"
            type="email"
            id="inputEmail"
            value={this.state.email}
            onChange={this.handleInputChange}
            disabled={this.state.subscribing}
            required
          />
        </div>
        <div className="form-group">
          <label for="inputFirstName">First name</label>
          <input
            className="form-control"
            id="inputFirstName"
            type="text"
            name="firstName"
            value={this.state.firstName}
            onChange={this.handleInputChange}
            disabled={this.state.subscribing}
          />
        </div>
        <div className="form-group">
          <label for="inputLastName">Last name</label>
          <input
            className="form-control"
            id="inputLastName"
            type="text"
            name="lastName"
            value={this.state.lastName}
            onChange={this.handleInputChange}
            disabled={this.state.subscribing}
          />
        </div>
        <div className="form-group">
          <label for="inputCity">City</label>
          <input
            className="form-control"
            id="inputCity"
            type="text"
            name="city"
            value={this.state.city}
            onChange={this.handleInputChange}
            disabled={this.state.subscribing}
          />
        </div>
        <div className="form-group">
          <label for="inputState">State</label>
          <input
            className="form-control"
            id="inputState"
            type="text"
            name="state"
            value={this.state.state}
            onChange={this.handleInputChange}
            disabled={this.state.subscribing}
          />
        </div>
        <div className="form-group">
          <label for="inputZip">Zip</label>
          <input
            className="form-control"
            id="inputZip"
            type="text"
            name="zip"
            value={this.state.zip}
            onChange={this.handleInputChange}
            disabled={this.state.subscribing}
          />
        </div>

        {(this.state.subscribed || this.state.failedSubscribe) && (
          <div
            className={
              this.state.subscribed
                ? "signup-notification success"
                : "signup-notification error"
            }
          >
            {this.state.subscribed
              ? "Success! You are now subscribed."
              : "Subscription failed"}
          </div>
        )}

        <button
          type="submit"
          className="btn btn-primary"
          disabled={this.state.subscribing}
        >
          {this.state.subscribing ? (
            <span className="submit-button-content">
              <span className="">Subscribing</span>
              <div className="loader ml-2">Loading</div>
            </span>
          ) : (
            <span>Submit</span>
          )}
        </button>
      </form>
    );
  }
}
