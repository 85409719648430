import React, { Component } from "react";
import { withSoundCloudAudio } from "react-soundplayer/addons";
import {
  PlayButton,
  Progress,
  VolumeControl,
  Timer
} from "react-soundplayer/components";
require("react-soundplayer/styles/buttons.css");
require("react-soundplayer/styles/cover.css");
require("react-soundplayer/styles/progress.css");
require("react-soundplayer/styles/volume.css");
require("react-soundplayer/styles/icons.css");

class CustomSoundPlayer extends Component {
  render() {
    const { currentTime, duration } = this.props;

    return (
      <div className="custom-player">
        <PlayButton
          className="custom-play-button mr-2"
          buttonClassName="custom-play-button"
          {...this.props}
        />
        <VolumeControl
          className="volume"
          buttonClassName="custom-volume-control flex-none"
          rangeClassName="custom-range-control"
          {...this.props}
        />
        <div className="progress-timer">
          <Progress
            className="rounded ml-4"
            innerClassName="custom-progress-inner"
            value={(currentTime / duration) * 100 || 0}
            dragable
            {...this.props}
          />
          <Timer
            className="timer"
            duration={duration}
            currentTime={currentTime}
          />
        </div>
      </div>
    );
  }
}

export default withSoundCloudAudio(CustomSoundPlayer);
